import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 3
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"THREE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"Which of the following is a way to rewire the brain?"}
        answers={[
          "Practice",
          "Physical activity",
          "Repetition",
          "All of the above",
        ]}
        correctAnswerNumber={3}
        answerDetail={
          "Physical activity impacts the way our brain grows and develops. While the brain isn't a muscle is functions like one, imrproving in the ways it is most commonly used. Additionally, practice and repition help strengthen the pathways in our brain to build new skills."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 3, 5)
